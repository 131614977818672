'use client';

import { useState } from 'react';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { zodResolver } from '@hookform/resolvers/zod';
import { Eye, EyeOff, Loader2, LogIn } from 'lucide-react';
import { signIn } from 'next-auth/react';
import { useForm, type SubmitHandler, type UseFormProps } from 'react-hook-form';
import { toast } from 'sonner';

import { defaultFormConfig } from '@@configs/form';
import { SignInForm_Type, SignInForm_validation } from '@@configs/validations/auth';
import { Button } from '@@components/ui/button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@@components/ui/card';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@@components/ui/form';
import { Input } from '@@components/ui/input';
import { Separator } from '@@components/ui/separator';
import Skeleton from '@@components/ui/skeleton';
import AuthLinks from '@@components/auth/AuthLinks';

// import FacebookAuthBtn from '@@components/auth/FacebookAuthBtn';
// import GoogleAuthBtn from '@@components/auth/GoogleAuthBtn';

/**
 * Dynamic imports
 */
const GoogleAuthBtn = dynamic(() => import('@@components/auth/GoogleAuthBtn'), { loading: () => <Skeleton style={{ minWidth: 120, height: 40 }} /> });
const FacebookAuthBtn = dynamic(() => import('@@components/auth/FacebookAuthBtn'), { loading: () => <Skeleton style={{ minWidth: 120, height: 40 }} /> });
// import AuthLinksSkeleton from '@@components/auth/AuthLink.skeleton';
// const AuthLinks = dynamic(() => import('@@components/auth/AuthLinks.component'), { loading: () => <AuthLinksSkeleton /> });

/**
 * Types
 */
type FormDataType = SignInForm_Type;
type LoginPageProps = {
  params?: Record<string, string>;
  searchParams?: Record<string, string>;
};

/**
 * Constants
 */
const defaultValues = { email: '', password: '' } satisfies FormDataType;
const FormConfig = {
  ...defaultFormConfig,
  defaultValues,
  resolver: zodResolver(SignInForm_validation),
  resetOptions: { keepDefaultValues: false, keepTouched: true },
} satisfies UseFormProps;

/**
 * Component: LoginPage
 */
// eslint-disable-next-line unused-imports/no-unused-vars
export default function LoginPage({ searchParams = defaultValues }: LoginPageProps) {
  const router = useRouter();
  const form = useForm<FormDataType>({ ...FormConfig, defaultValues: { ...defaultValues, ...searchParams } });
  const { isValidating, isSubmitting, isValid } = form.formState;
  const [ShowPassword, setShowPassword] = useState(false);
  const onShowPassword = () => setShowPassword((v) => !v);

  const onSubmit: SubmitHandler<FormDataType> = async (data) => {
    router.prefetch('/');
    const signinResponse = await signIn('credentials', { ...data, redirect: false, callbackUrl: '/' }).then((res) => {
      if (!res?.ok && res?.error) toast.error(res.error, { position: 'top-center' });
      else toast.success("You're successfully logged in");
      return res;
    });
    console.log('🚀➡️onSubmit ➡️ data:', data, signinResponse);
    if (signinResponse?.ok) router.push('/');
  };

  return (
    <main className="m-auto grid place-items-center gap-8 p-3">
      <Form {...form}>
        <Card as="form" className="grid w-full min-w-[350px] max-w-md" onSubmit={form.handleSubmit(onSubmit)}>
          <CardHeader className="text-center">
            <CardTitle>Sign in</CardTitle>
            <CardDescription>Tag and Meet the digital namecard</CardDescription>
          </CardHeader>
          <CardContent>
            {/* Facebook & Google */}
            <div className="flex justify-center gap-4">
              <GoogleAuthBtn />
              <FacebookAuthBtn disabled />
            </div>

            {/* Separator */}
            <Separator className="mb-2 mt-5" text="OR" />

            <div className="grid">
              {/* Email */}
              <FormField
                control={form.control}
                name="email"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel>Email</FormLabel>
                    <FormControl>
                      <Input
                        {...field} //
                        autoComplete="email"
                        placeholder="yourname@email.com"
                        color={fieldState.error && 'error'}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              {/* Password */}
              <FormField
                control={form.control}
                name="password"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel>Password</FormLabel>
                    <FormControl>
                      <div className="flex gap-1">
                        <Input
                          {...field} //
                          color={fieldState.error && 'error'}
                          type={ShowPassword ? 'text' : 'password'}
                        />
                        <Button type="button" size="icon" variant="ghost" className="flex-fill" onClick={onShowPassword}>
                          {ShowPassword ? <Eye /> : <EyeOff />}
                        </Button>
                      </div>
                    </FormControl>
                    {/* <FormDescription>This is your public display name.</FormDescription> */}
                    <FormMessage />
                  </FormItem>
                )}
              />

              <Button
                type="submit"
                className="mx-auto mt-4 gap-2" //
                variant="default"
                disabled={!isValid || isValidating || isSubmitting}
              >
                {(isValidating || isSubmitting) && <Loader2 className="mr-0 size-5 animate-spin" />}
                {!isValidating && !isSubmitting && <LogIn className="mr-0 size-5" />}
                Sign in
              </Button>

              <div className="-m-4 mt-5 flex justify-end">
                {/* <Button as={Link} href="/verify-email" variant="link" size="sm" className="text-xs">
                  Verify email?
                </Button> */}
                <Button as={Link} href="/forgot-password" variant="link" size="sm" className="text-xs">
                  Forgot password?
                </Button>
              </div>
            </div>

            {/* <p>isLoading: {form.formState.isLoading.toString()}</p>
            <p>isValidating: {isValidating.toString()}</p>
            <p>isSubmitting: {isSubmitting.toString()}</p>
            <p>isValid: {isValid.toString()}</p> */}
          </CardContent>

          {/* <CardFooter className="grid"></CardFooter> */}
        </Card>
      </Form>

      {/* Sign up */}
      <AuthLinks variant="signup" />
    </main>
  );
}
