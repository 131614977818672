'use client';

import * as React from 'react';
import * as SeparatorPrimitive from '@radix-ui/react-separator';

import { cn } from '@@libs/utils';

/**
 * Types
 */
type SeparatorProps = { text: React.ReactNode; uppercase?: boolean } | { text?: never; uppercase?: never };

/**
 * Component: Separator
 */
const Separator = React.forwardRef<
  React.ElementRef<typeof SeparatorPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof SeparatorPrimitive.Root> & SeparatorProps
>(({ text, uppercase, className, orientation = 'horizontal', decorative = true, ...props }, ref) =>
  text ? (
    <div className={cn('relative shrink-0', className)}>
      <div className="absolute inset-0 flex items-center">
        <span className="w-full border-t" />
      </div>
      <div className={cn('relative flex justify-center text-xs', { uppercase })}>
        <span className="bg-background px-2 text-card-foreground/75">{text}</span>
      </div>
    </div>
  ) : (
    <SeparatorPrimitive.Root
      ref={ref}
      decorative={decorative}
      orientation={orientation}
      className={cn('shrink-0 bg-border', orientation === 'horizontal' ? 'h-[1px] w-full' : 'h-full w-[1px]', className)}
      {...props}
    />
  )
);
Separator.displayName = SeparatorPrimitive.Root.displayName;

export { Separator };
